import { Injectable } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccessToPageGuard {
  constructor(
    private router: Router,
    private userStorage: CurrentUserStorage,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const permissionObjectsToCheck = route.data.permissionObjects;

    if (typeof permissionObjectsToCheck === 'undefined') {
      return true;
    }

    const containsAll = permissionObjectsToCheck.every((item: string) =>
      this.userStorage.getCurrentUser()?.permissionObjects.includes(item),
    );

    if (containsAll) {
      return true;
    }

    if (!this.userStorage.hasB2BStudentRole() && route.data.allowForB2C) {
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
