import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(public platform: Platform) {}
  isSafari(): boolean {
    return this.platform.SAFARI;
  }
  isIos(): boolean {
    return this.platform.IOS;
  }

  isAndroid(): boolean {
    return this.platform.ANDROID;
  }
}
